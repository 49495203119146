<template>
  <q-footer class="bg-grey-10 text-white print-hide">
    <q-toolbar>
      <q-toolbar-title>
        <div class="text-grey-2 text-center text-caption">
          Copyright &copy; {{ year }} <a href="https://southeasternaluminum.com" target="_blank">Southeastern Aluminum</a> | All
          Rights Reserved | version 1.0.0{{ isDev }}
        </div>
      </q-toolbar-title>
    </q-toolbar>
  </q-footer>
</template>

<script>
export default {
  setup() {
    const year = new Date().getFullYear();
    const isDev = process.env.NODE_ENV === "development" ? ".d" : ".p";

    return {
      year,
      isDev
    };
  }
};
</script>
