<template>
  <img
    alt="Southeastern Aluminum Logo"
    src="@/assets/logos/logo-red.png"
    class="print-hide"
    style="max-width: 50px; max-height: 50px"
  />
  <div class="print-hide q-ml-md q-mr-sm company-name gt-xs text-weight-medium">Southeastern Aluminum Products</div>
  <div class="print-hide q-ml-md q-mr-sm company-name lt-sm text-weight-medium">SEAP</div>
</template>
